import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import { fontWeight } from "src/ui/fontWeight"
import { InternalNavLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

export const IconBox = styled.div`
  width: 24px;
  margin-right: ${spacing.M};
  margin-left: ${spacing.XS};
`

export const StyledDivider = styled(Divider)`
  margin: ${spacing.M} 0;
  background: ${mColors.divider};
`

export const LogoBox = styled.div`
  margin-top: ${spacing.M};
  padding: ${spacing.S} ${spacing.M};
  margin-left: ${spacing.S};
  border-left: 4px solid transparent;
`

export const NavMain = styled.ul<{
  $color: string
  $hoverBgColor: string
  $activeBgColor: string
  $activeColor: string
}>`
  display: flex;
  flex-direction: column;

  & a {
    font-size: 0.875rem;
    font-weight: ${fontWeight.regular};
    line-height: 1.5;
    color: ${(props) => props.$color};
    margin-bottom: 1px;
    text-decoration: none;
  }

  & a:hover {
    border-radius: 5px;
    text-decoration: none;
    background: ${(props) => props.$hoverBgColor};
  }

  & a[disabled],
  a[aria-disabled] {
    opacity: 0.5;
    pointer-events: none;
  }

  a.is-active {
    border-radius: 5px;
    font-weight: ${fontWeight.semiBold};
    opacity: 1;
    color: ${(props) => props.$activeColor};
    background: ${(props) => props.$activeBgColor};
  }
`

export const SharedStyledNavLink = styled(InternalNavLink)`
  padding: ${spacing.XS} ${spacing.XS};
  margin: 0 ${spacing.S};
  display: flex;
  align-items: center;
  transition: all 0.1s ease-in-out;
`

export const NavFooter = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
`

export const ProfileButtonSpacer = styled.div`
  > * {
    padding-left: calc(${spacing.M} + ${spacing.S});
  }
`

export const SideNavBox = styled.nav<{ $background: string }>`
  height: 100dvh;
  overflow: auto;
  position: sticky;
  top: 0;
  width: 250px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${mColors.divider};
  background: ${(props) => props.$background};
`

/** @deprecated this shouldn't be imported directly; use SideNavLink instead. */
export const StyledNavLink = styled(SharedStyledNavLink)`
  color: ${mColors.primary};
`
